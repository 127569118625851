<template>
  <h1>Json Pretty / UnPretty</h1>
  <img
    alt="Vue logo"
    src="@/assets/logo.png"
  >
</template>

<script>
export default {
name: "StringLength"
}
</script>

<style scoped>

</style>